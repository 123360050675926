import { useDispatch } from 'react-redux';

import { auth as types } from '@codesass/types';

import { useLocation } from '@reach/router';

import * as actions from '../slice';

import AuthForm from './Form';

type State = {
  prev?: string;
};

const Login = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const prevPath = (state as State)?.prev;

  const login = (userCredential: types.UserCredential) => {
    dispatch(actions.login({ ...userCredential, prevPath }));
  };

  return (
    <AuthForm
      variant="login"
      title="เข้าสู่ระบบ"
      altTitle="ยังไม่มีบัญชีผู้ใช้งาน?"
      altText="ลงทะเบียน"
      altLink="/auth/register"
      onSubmit={login}
    ></AuthForm>
  );
};

export default Login;
