import Login from 'modules/auth/components/Login';
import PreventLoggedInGuard from 'modules/auth/components/PreventLoggedInGuard';

const LoginPage = () => {
  return (
    <PreventLoggedInGuard>
      <Login></Login>
    </PreventLoggedInGuard>
  );
};

export default LoginPage;
